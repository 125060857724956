@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;1,200&display=swap);

@font-face {
  font-family: 'SF Pro Display';
  src: URL('./assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'SF Pro Display';
  src: URL('./assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'SF Pro Display';
  src: URL('./assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'SF Pro Display';
  src: URL('./assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
}

html, body, .container {
  height:100%;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.privacy-middle-left {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  color: #0B18B18C;
  font-size: 16px;
  margin-top: -10px;
}

.privacy-middle-right {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: #A9DBFEC4;
  font-size: 16px;
  margin-top: -10px;
}

.breathing-intro-section {
  position: relative;
  height: 100%;
  font-family: 'SF Pro Display', sans-serif;
  padding-top: 10vh;
  min-height: 100vh !important;
}

.breathing-intro-section .title {
  font-weight: 600 !important;
  font-size: 5vh;
  color: #1123D5;
  text-align: center;
}

.breathing-intro-section .description {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  color: #1123D5;
  padding-top: 3vh !important;
}

.breathing-intro-section .app-images-carousel {
  text-align: center;
}

.breathing-intro-section .app-images-carousel .left-or-right-img {
  height: 350px;
}
.breathing-intro-section .app-images-carousel .middle-img {
  height: 400px;
}

.breathing-intro-section .app-download-area {
  margin: 0 auto;
  text-align: center;
}

.breathing-intro-section .app-download-area .title {
  font-weight: 500 !important;
  color: #1123D5;
  font-size: 18px;
  text-transform: uppercase;;
}

.breathing-intro-section .app-download-area .download-sources .icon {
  height: 52px;
  width: 162px;
  cursor: pointer;
}

.explaning-section {
  font-family: 'SF Pro Display', sans-serif;
  background: #0C037D;
  text-align: center;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.explaning-section .content {
  margin: 0 auto;
  padding-top: 13vh;
  max-width: 612px;
}

.explaning-section .content  h2 {
  color: #A9DBFE;
  font-weight: 400 !important;
  font-size: 3vh;
}

.explaning-section .content .description {
  color: #A9DBFE;
  line-height: 28px;
  font-weight: 400 !important;
  text-align: center;
  max-width: 550px;
}

.explaning-section .content .video-box {
  margin-left: -20px;  
}

.explaning-section .content .video-box .video {
  max-width: 651px;
  margin-left: -20px;  
}

.explaning-section .content .newsletter {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.explaning-section .content .newsletter .title {
  display: flex;
  color: #A9DBFEC4;
  text-transform: uppercase;
}

.explaning-section .content .newsletter .inputs {
  margin-left: -15px;
}

.explaning-section .content .newsletter .email-field:focus {
  outline: none;
}

.explaning-section .content .newsletter .email-bg {
  width: 325px;
  height: 65px;
  padding: 5px;
  background: url(../../static/media/email-input-field.ed024fef.png) no-repeat top left;
  padding-left: 25px;
  margin-top: 6px;
  background-size: 100% 90%;
}
.explaning-section .content .newsletter .email-field {
  border: none;
  height: 50px;
  width: 300px;
  padding: 12px;
  color: #B5E9FE8C;
  background: none;
  margin-left: -15px;
}

.email-field::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #007CE8;
}

.email-field::placeholder { /* Most modern browsers support this now. */
  color:  #007CE8;
}


.explaning-section .content .newsletter .join-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #B5E9FE;
  margin-top: -22px;
  height: 102px;
  width: 200px;
  margin-left: 20px;
  background: url(../../static/media/join-newsletter-button.76957653.png) no-repeat top left;
  background-size: 100% 100%;
  transition: 0.5s;
}

.explaning-section .content .newsletter .join-btn span {
  margin-top: 20px;
}

.explaning-section .content .newsletter .join-btn:hover {
  background: url(../../static/media/hover-join-newsletter-button.bf8b9397.png) no-repeat top left;
  background-size: 100% 93%;
}

lottie-player {
  margin-top: -1000px;
}

@media (max-width: 670px){
  .breathing-intro-section .app-images-carousel .left-or-right-img {
    height: 200px;
  }
  .breathing-intro-section .app-images-carousel .middle-img {
    height: 250px;
  }
}

@media (max-width: 1199px){
  .breathing-intro-section {
    padding-bottom: 60px;
  }
}

@media (min-width: 1200px) and (max-width:1400px){
  .breathing-intro-section .app-images-carousel .left-or-right-img {
    height: 300px;
  }
  .breathing-intro-section .app-images-carousel .middle-img {
    height: 350px;
  }
  .explaning-section .content {
    margin-left: 40px;
  }
}

@media (max-width: 1199px){
  .breathing-intro-section {
    padding-bottom: 60px;
  }
}

@media (min-width: 2000px){

  .breathing-intro-section .description {
    max-width: 60%;
    font-size: 1.7vh;
  }

  .breathing-intro-section .app-images-carousel {
    padding-top: 2vh !important;
  }

  .breathing-intro-section .app-download-area {
    padding-top: 2vh !important;
  }

  .breathing-intro-section .app-download-area .title {
    font-size: 1.9vh;
  }

  .breathing-intro-section .app-download-area .download-sources .icon {
    width: 15vh;
    height: 5vh;
  }

  .explaning-section {
    background-size: cover;
  }

  .explaning-section .content {
    max-width: 80%;
  }

  .explaning-section .content .description {
    padding-top: 2vh !important;
    font-size: 1.5vh;
    line-height: 3vh;
    margin: 0 auto;
    max-width: 70%;
  }

  .left-or-right-img{
    height: 35vh !important;
  }
  .middle-img{
    height: 40vh !important;
  }
  
  .explaning-section .content .video-box .video {
    max-width: 50vh;
    margin-left: 0;
  }

  .explaning-section .newsletter {
    padding: 10vh !important;
  }

  .explaning-section .newsletter .inputs {
    padding-top: 0.5rem;
  }

  .explaning-section .newsletter .title {
    font-size: 1.5vh;
  }

  .explaning-section .content .newsletter .email-bg {
    width: 25vh;
    height: 5vh;
  }

  .explaning-section .content .newsletter .email-field {
    font-size: 1.5vh;
  }
  
  .explaning-section .content .newsletter .join-btn {
    font-size: 1.5vh;
    width: 25vh;
    margin-top: -2.5vh;
    height: 8vh;
  }

  .explaning-section .content .newsletter .join-btn span{
   margin-top: 2vh;
  }
  
  .privacy-middle-left, .privacy-middle-right {
    font-size: 1.8vh;
    padding: 1vh !important;
  }
}

